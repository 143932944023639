import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Swal from "sweetalert2";
import ButtonNeoGen from "../../layout/button";
import Main from "../../layout/Main";
import PageDescription from "../../layout/page-description";
import PrintPre from "../../layout/print-pre";
import SelectNeoGen from "../../layout/select-neogen";
import TableNeogen from "../../layout/table-neogen";
import authService from "../../services/auth.service";
import interactionsService from "../../services/interactions.service";
import usersService from "../../services/users.service";
import { ClearERCUser } from "../../typings/api/clear-erc-user";
import { ErcUser } from "../../typings/api/erc-user";
// import users from "../admin/users/users";
import Loader2 from "../utilities/Loader2";
import NewMessage from "./modals/new-message";
import ShowMessage from "./modals/show-message";

function compareUsers(a: ClearERCUser, b: ClearERCUser) {
    const aSort = a.name ?? a.email;
    const bSort = b.name ?? b.email;
    if (aSort.toLowerCase() < bSort.toLowerCase()) {
        return -1;
    }
    if (aSort.toLowerCase() > bSort.toLowerCase()) {
        return 1;
    }
    return 0;
}

export default function Messages() {
    const [show, setShow] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [messageId, setMessageId] = useState(0);
    const [selectedUser, setSelectedUser] = useState("");
    // const [users, setUsers] = useState<ErcUser[]>([]);
    const { id } = useParams();

    const [filteredMessages, setFilteredMessages] = useState<any[]>([]);
    const usersQuery = useQuery(["users", "sorted", compareUsers], async () => {
        const response = await usersService.getAll();
        if (response) {
            return response.data.sort(compareUsers);
        }
        return [];
    });

    const messagesQuery = useQuery(["messages"], async () => {
        const response = await interactionsService.getAll();
        if (response) {
            return response.data;
        }
    });

    useEffect(() => {
        if (id) {
            setSelectedUser(id);
        }
    }, [id]);

    useEffect(() => {
        if (messagesQuery.data) {
            if (selectedUser) {
                setFilteredMessages(
                    messagesQuery.data.filter((message) => {
                        return (
                            (message.interactionFrom === selectedUser ||
                                message.interactionTo === selectedUser ||
                                message.interactionRegarding === selectedUser) &&
                            message.type === 1
                        );
                    }),
                );
            } else {
                setFilteredMessages(messagesQuery.data.filter((m) => m.type === 1));
            }
        }
    }, [messagesQuery.data, selectedUser]);

    if (messagesQuery.isLoading) {
        return <Loader2 />;
    }

    return (
        <>
            <PageDescription
                title="Messages"
                description="This is a list of all your messages."
                buttons={[
                    {
                        label: "New Message",
                        onClick: () => {
                            // setIsEdit(false);
                            setShow(true);
                        },
                        icon: "fal fa-plus",
                        type: "primary",
                        className: "inline-block w-96",
                    },
                ]}
                filters={[
                    {
                        label: "User Filter",
                        value: selectedUser,
                        options: (usersQuery.data ?? []).map((user) => {
                            return {
                                name: user.name ?? user.email,
                                id: user.id ?? "",
                            };
                        }),
                        onChange: (value) => {
                            // alert(value);
                            setSelectedUser(value.toString());
                        },
                    },
                ]}
            >
                {/* <div className="flex flex-row gap-5"> */}
                {/* <div className="flex-grow"> */}
                {/* <SelectNeoGen
                    onChange={(value) => {
                        console.log(value);
                        setSelectedUser(value.toString());
                    }}
                    label="Uploaded By"
                    options={users}
                    value={selectedUser ?? ""}
                /> */}
                {/* </div> */}
                {/* </div> */}
            </PageDescription>
            <Main>
                <TableNeogen
                    formatters={[
                        {
                            field: "dateCreated",
                            type: "DateTime",
                        },
                        {
                            field: "followupDatetime",
                            type: "DateTime",
                        },
                        {
                            field: "needsFollowUp",
                            type: "Boolean",
                        },
                        {
                            field: "read",
                            type: "Boolean",
                        },
                        {
                            field: "interactionFrom",
                            type: "User",
                        },
                        {
                            field: "interactionTo",
                            type: "User",
                        },
                        {
                            field: "interactionRegarding",
                            type: "User",
                        },
                        {
                            field: "type",
                            type: "MessageType",
                        },
                    ]}
                    ignoreFields={["activity", "summary", "type"]}
                    entries={filteredMessages ?? []}
                    actions={[
                        {
                            label: "Open Message",
                            onClick: (id) => {
                                // setIsEdit(true);
                                // setEditID(id);
                                setMessageId(id);
                                setShowMessage(true);
                            },
                            icon: "fal fa-folder-open",
                            className: "bg-blue-500 hover:bg-blue-600 text-white",
                        },

                        {
                            label: "Delete",
                            onClick: (id) => {
                                Swal.fire({
                                    title: "Are you sure?",
                                    text: "You won't be able to revert this!",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonText: "Yes, delete it!",

                                    confirmButtonColor: "#d33",
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        interactionsService
                                            .deleteByID(id)
                                            .then(() => {
                                                Swal.fire({
                                                    icon: "success",
                                                    title: "Deleted!",
                                                    text: "Your message has been deleted.",
                                                    showConfirmButton: false,
                                                    timer: 1500,
                                                });
                                                messagesQuery.refetch();
                                            })
                                            .catch(() => {
                                                Swal.fire("Error!", "Something went wrong.", "error");
                                            });
                                    }
                                });
                            },
                            icon: "fal fa-trash",
                            className: "bg-red-500 hover:bg-red-600 text-white",
                        },
                    ]}
                />
            </Main>
            <NewMessage show={show} setShow={setShow} type={1} to={id} />
            <ShowMessage id={messageId} show={showMessage} setShow={setShowMessage} />
        </>
    );
}
