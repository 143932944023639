import { LockClosedIcon } from "@heroicons/react/24/solid";
import { useCallback, useLayoutEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../images/logo.png";
import authService from "../../services/auth.service";
import InputControlled from "../../layout/input-controlled";
import { useRecoilState } from "recoil";
import userAtom from "../../atoms/userAtom";
import { ClearERCUser } from "../../typings/api/clear-erc-user";
import processflowProgressService from "../../services/processflow-progress.service";
import progressDataAtom from "../../atoms/progressDataAtom";
//import stageAtom from "../../atoms/stageAtom";
//import stepAtom from "../../atoms/stepAtom";
import { ProcessFlowProgressData } from "../../typings/api/processflow-progress-data";
import { useQuery } from "@tanstack/react-query";
import Swal from "sweetalert2";
import processflowStageService from "../../services/processflow-stage.service";
import processflowService from "../../services/processflow.service";
import { ProcessFlow } from "../../typings/api/processflow";
import useStep from "../processflows/hooks/use-step";
import Loader2 from "../utilities/Loader2";
import stepAtom from "../../atoms/stepAtom";
import stageAtom from "../../atoms/stageAtom";
import positionAtom, { Position } from "../../atoms/positionAtom";
import processflowProgressIdAtom from "../../atoms/processflowProgressIdAtom";
import React from "react";
import PrintPre from "../../layout/print-pre";
import logService from "../../services/log-service";
import { Log } from "../../typings/api";
import axios from "axios";
import { getGeoData } from "../../jason-proof-of-concept/other/actions/getMyIp";
import * as Sentry from "@sentry/browser";

export default function SignInForm() {
    const usernameElement = React.useRef<HTMLInputElement>(null);
    const passwordElement = React.useRef<HTMLInputElement>(null);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [user, setUser] = useRecoilState<ClearERCUser>(userAtom);
    // const [internalCurrentStage, setInternalCurrentStage] = useRecoilState<number>(stageAtom);
    const [progressData, setProgressData] = useRecoilState<ProcessFlowProgressData>(progressDataAtom);
    // const [currentStepId, setCurrentStep] = useRecoilState<ProcessFlow>(stepAtom);
    const [position, setPosition] = useRecoilState<Position>(positionAtom);
    const [processFlowProgressId, setProcessFlowProgressId] = useRecoilState<number>(processflowProgressIdAtom);
    const [loggingIn, setLoggingIn] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const processFlowGroup = 9; // TODO: this should not be hardcoded
    const { isLoading, getStepFromId } = useStep({ groupId: processFlowGroup });

    React.useEffect(() => {
        //handle autocomplite
        setEmail(usernameElement?.current?.value ?? "");
        setPassword(passwordElement?.current?.value ?? "");
    }, [usernameElement, passwordElement]);

    const entriesQuery = useQuery(
        processflowService.getCacheKey({
            currentStep: position.stepId?.toString(),
            processFlowGroup: processFlowGroup.toString(),
            relation: "relation",
            include: "businessRules",
            identifier: "SignInForm",
        }),
        async () => {
            // alert(position.stepId);
            if (position.stepId > 0) {
                const responseStep = await processflowService.getOne(Number(position.stepId));
                if (responseStep) {
                    const step = responseStep.data;
                    const response = await processflowService.getAllByOrderForStageAndGroup(
                        step.stage,
                        Number(processFlowGroup),
                    );
                    if (response) {
                        // console.info({ response })
                        return response.data;
                    }
                }
            } else {
                return [];
            }
        },
    );

    const stageQuery = useQuery(["processflow-stages", "getAllByGroupId", processFlowGroup], async () => {
        console.log(processFlowGroup);
        if (processFlowGroup > 0) {
            const response = await processflowStageService.getAllByGroupIdIncludingRuleGroups(processFlowGroup);
            if (response) {
                return response.data;
            } else {
                // Swal.fire({
                //     title: "Error",
                //     text: "There was an error getting the stages for this process flow group",
                //     icon: "error",
                //     confirmButtonText: "OK"
                // });
                return [];
            }
        }
    });

    const createDefaultProgress = useCallback(
        async (userId: string) => {
            // alert(stageQuery.data);

            const blankEntry: ProcessFlowProgressData = {
                completedSteps: [],
                entriesByField: {},
                group: 0,
                // currentStage: -1,
                // currentStep: -1
            };
            try {
                const newEntryResponse = await processflowProgressService.create({
                    userId: userId,
                    data: JSON.stringify(blankEntry),
                    group: processFlowGroup,
                    currentStage: stageQuery.data?.[0]?.id ?? 19,
                    currentStep: entriesQuery.data?.[0]?.id ?? 32,
                });
                setPosition({
                    stageId: stageQuery.data?.[0]?.id ?? 19,
                    stepId: entriesQuery.data?.[0]?.id ?? 32,
                });
            } catch (error: any) {
                console.error(error.response.data.error.code);
                if (error.response.data.error.code === "ER_DUP_ENTRY") {
                    // pull the data and patch it
                    // alert("Patching");
                    const progressData = await processflowProgressService.getProgress(userId, processFlowGroup);
                    if (progressData) {
                        return progressData.data[0];
                    }
                }
                Sentry.captureException(error);
                throw new Error("Failed to create new progress entry");
            }
        },
        [entriesQuery.data, setPosition, stageQuery.data],
    );

    // useLayoutEffect(() => {
    // const input = document.getElementById("#email");
    // input?.focus();
    // }, []);

    async function login() {
        try {
            setLoggingIn(true);

            if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
                // dark mode
                document.body.classList.add("dark-theme");
                document.body.classList.add("dark");

                localStorage.setItem("theme", "dark");
            } else {
                // light mode
                localStorage.setItem("theme", "light");
                document.body.classList.remove("dark-theme");
                document.body.classList.remove("dark");
            }
            const response = await authService.login(email, password);

            if (response.id) {
                setUser(response.user);

                // find the user's processflow progress and store it in local storage
                const pfp = await processflowProgressService.getProgress(response.id, 7);
                if (pfp && pfp.data[0]) {
                    const progress = pfp.data[0];
                    const parsed = JSON.parse(progress.data ?? "{}");
                    setProgressData(parsed);
                    const step = getStepFromId(parsed.currentStep);
                    if (step && step.id) {
                        setPosition({ stepId: step.id, stageId: step.stage });
                    } else {
                        setPosition((c: Position) => ({ ...c, stageId: parsed.currentStage }));
                    }
                    console.log("Set primary details");
                } else {
                    const value = await createDefaultProgress(response.id); // TODO check to see if this created setProcessFlowProgressId
                    console.log("Created default progress because no pfp in response ", value);
                    setProcessFlowProgressId(value?.id ?? -1);
                    const step = getStepFromId(value?.currentStep);
                    if (value) {
                        if (step && step.id) {
                            // setCurrentStep(step);
                            setPosition({ stepId: step.id, stageId: step.stage });
                        } else {
                            setPosition((c: Position) => ({ ...c, stageId: value.currentStage }));
                        }
                    }

                    if (step?.id) {
                        setPosition((c: Position) => ({ ...c, stepId: step.id ?? c.stepId }));
                        // setCurrentStep(step);
                    }

                    setPosition((c: Position) => ({ ...c, stageId: value?.currentStage ?? -1 }));
                }
                // This request is sometimes blocked by ad-blockers (AdBlock, uBlock etc)
                getGeoData().then((geoData) => {
                    const newLogin: Log = {
                        userId: response.id,
                        remoteIp: geoData?.IPv4,
                        eventTimestamp: new Date().toISOString(),
                        url: "/login",
                        body: JSON.stringify(geoData),
                        executionTime: 0,
                    };
                    logService.postURL("/logs", newLogin).then((response) => {
                        console.error(response ? "Successfully logged login" : "Failed to log login");
                        console.error(response);
                    });
                });
                // console.log(res.data);
                // return (res.data);
                // };
                // sendSlackDebug(
                //     JSON.stringify({ newUser: newUserData, utm }),
                // )
                // sendSlackSale(
                //     JSON.stringify({ newUser: newUserData, utm }),
                // )
                // const body = `Country:  ${geoData.country_name}\nCity: ${geoData.city}\nState: ${geoData.state}\nIP: ${geoData.IPv4}\nURL: ${window.location}\nReferrer: ${document.referrer}\n`;
                // alert(JSON.stringify(response.roles));
                authService.roles = response.roles;
                setLoggingIn(false);
                navigate(location.state?.from ?? "/dashboard");
            } else {
                setError(response.message);
                setLoggingIn(false);
            }
        } catch (error: any) {
            console.error(error);
            setError(
                // Temporary hack to display user-friendly error messages on login.
                error.message === "Request failed with status code 422"
                    ? "Invalid username or password."
                    : error.message === "Invalid Username/Password"
                    ? "Incorrect username and password combination."
                    : error.message,
            );
            setLoggingIn(false);
        }
    }
    return (
        <>
            <div className="min-h-full flex items-center justify-center py-12 px-0 sm:px-6 lg:px-12 ">
                <form>
                    <div className="max-w-lg md:w-[600px] space-y-10 p-6 md:p-14 rounded-xl  bg-indigo-800 shadow-2xl shadow-slate-700/90 border-white/80 dark:border-slate-500 border    ">
                        <div>
                            <img className="mx-auto h-12 w-auto" src={logo} alt="Workflow" />
                        </div>

                        <input type="hidden" name="remember" defaultValue="true" />
                        <div className="rounded-md shadow-sm -space-y-px">
                            {error && <p className="text-red-500 text-sm mb-5">{error}</p>}
                            <div className="rounded-md shadow-sm -space-y-px">
                                {/* <input type="text" value="matt@neogen.ai"> */}
                                {/* <input type="password" value="murron-qoKbyp-7nomde"> */}
                                <div>
                                    <div>
                                        <div className="mb-5">
                                            <label
                                                htmlFor="email"
                                                className="block text-sm font-medium text-gray-300 dark:text-gray-400"
                                            >
                                                Email Address
                                            </label>
                                            <div className="mt-1relative rounded-md ">
                                                <input
                                                    ref={usernameElement}
                                                    id="email"
                                                    type="email"
                                                    className="undefined shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md dark:bg-gray-900  dark:border-gray-900 dark:text-gray-300"
                                                    placeholder=""
                                                    value={email}
                                                    onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        setEmail(event.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <div className="mb-5">
                                            <label
                                                htmlFor="password"
                                                className="block text-sm font-medium text-gray-300 dark:text-gray-400"
                                            >
                                                Password
                                            </label>
                                            <div className="mt-1relative rounded-md ">
                                                <input
                                                    ref={passwordElement}
                                                    id="password"
                                                    type="password"
                                                    className="undefined shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md dark:bg-gray-900  dark:border-gray-900 dark:text-gray-300"
                                                    value={password}
                                                    onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        setPassword(event.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {isLoading ? (
                            <Loader2 />
                        ) : (
                            <>
                                <div className="flex items-center justify-between">
                                    <div className="text-sm">
                                        <Link
                                            to="/forgot-password"
                                            className="font-medium text-indigo-200 hover:text-indigo-500"
                                        >
                                            Forgot your password?
                                        </Link>
                                    </div>
                                    <div className="text-sm">
                                        <Link
                                            to="/sign-up"
                                            className="font-medium text-indigo-200 hover:text-indigo-500"
                                        >
                                            Create a new Account
                                        </Link>
                                    </div>
                                </div>
                                <div>
                                    <button
                                        type="button"
                                        className={
                                            "group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md " +
                                            "text-white bg-indigo-600 hover:bg-indigo-500 hover:text-gray-200 focus:outline-none focus:ring-2 " +
                                            "focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-500"
                                        }
                                        onClick={() => {
                                            setTimeout(() => {
                                                login();
                                            }, 50);
                                        }}
                                    >
                                        <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                            {loggingIn ? (
                                                <svg
                                                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <circle
                                                        className="opacity-25"
                                                        cx="12"
                                                        cy="12"
                                                        r="10"
                                                        stroke="currentColor"
                                                        strokeWidth="4"
                                                    ></circle>
                                                    <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    ></path>
                                                </svg>
                                            ) : (
                                                <LockClosedIcon
                                                    className="h-5 w-5  c group-hover:text-indigo-400 transition-all duration-500"
                                                    aria-hidden="true"
                                                />
                                            )}
                                        </span>
                                        Sign in
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </form>
            </div>
        </>
    );
}
