/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  const colors = require('tailwindcss/colors')
  
  module.exports = {
    // ...
    theme: {
      extend: {
        colors: {
          rose: colors.rose,
        },
      },
    },
  }
  ```
*/
import { Fragment, useState } from "react";
import { ChatBubbleLeftEllipsisIcon, TagIcon, UserCircleIcon } from "@heroicons/react/20/solid";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import interactionsService from "../../../services/interactions.service";
import usersService from "../../../services/users.service";
import NewMessage from "../../messages/modals/new-message";
import ShowMessage from "../../messages/modals/show-message";
import PrintPre from "../../../layout/print-pre";
import Avatar from "react-avatar";
import { PhoneIcon } from "@heroicons/react/24/solid";

const activity = [
    {
        id: 1,
        type: "comment",
        person: { name: "Eduardo Benz", href: "#" },
        imageUrl:
            "https://images.unsplash.com/photo-1520785643438-5bf77931f493?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80",
        comment:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc ipsum tempor purus vitae id. Morbi in vestibulum nec varius. Et diam cursus quis sed purus nam. ",
        date: "6d ago",
    },
    {
        id: 4,
        type: "comment",
        person: { name: "Jason Meyers", href: "#" },
        imageUrl:
            "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80",
        comment:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc ipsum tempor purus vitae id. Morbi in vestibulum nec varius. Et diam cursus quis sed purus nam. Scelerisque amet elit non sit ut tincidunt condimentum. Nisl ultrices eu venenatis diam.",
        date: "2h ago",
    },
];

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

function timeAgo(time: string | number | Date) {
    if (time === null) {
        time = new Date();
    }
    switch (typeof time) {
        case "number":
            break;
        case "string":
            time = +new Date(time);
            break;
        case "object":
            if (time.constructor === Date) time = time.getTime();
            break;
        default:
            time = +new Date();
    }
    const time_formats = [
        [60, "seconds", 1], // 60
        [120, "1 minute ago", "1 minute from now"], // 60*2
        [3600, "minutes", 60], // 60*60, 60
        [7200, "1 hour ago", "1 hour from now"], // 60*60*2
        [86400, "hours", 3600], // 60*60*24, 60*60
        [172800, "Yesterday", "Tomorrow"], // 60*60*24*2
        [604800, "days", 86400], // 60*60*24*7, 60*60*24
        [1209600, "Last week", "Next week"], // 60*60*24*7*4*2
        [2419200, "weeks", 604800], // 60*60*24*7*4, 60*60*24*7
        [4838400, "Last month", "Next month"], // 60*60*24*7*4*2
        [29030400, "months", 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
        [58060800, "Last year", "Next year"], // 60*60*24*7*4*12*2
        [2903040000, "years", 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
        [5806080000, "Last century", "Next century"], // 60*60*24*7*4*12*100*2
        [58060800000, "centuries", 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
    ];
    let seconds = Number((+new Date() - Number(time)) / 1000),
        token = "ago",
        list_choice = 1;

    if (seconds == 0) {
        return "Just now";
    }
    if (seconds < 0) {
        seconds = Math.abs(seconds);
        token = "from now";
        list_choice = 2;
    }
    let i = 0,
        format;
    while ((format = time_formats[i++]))
        if (seconds < format[0]) {
            if (typeof format[2] == "string") return format[list_choice];
            else return Math.floor(seconds / format[2]) + " " + format[1] + " " + token;
        }
    return time;
}

export default function MessagesList() {
    const { id } = useParams();
    const [show, setShow] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [messageId, setMessageId] = useState(0);
    const [selectedUser, setSelectedUser] = useState("");
    // const [users, setUsers] = useState<ErcUser[]>([]);
    const [page, setPage] = useState("basic");
    const usersQuery = useQuery(["users"], async () => {
        const response = await usersService.getAll();
        if (response) {
            return response.data;
        }
        return [];
    });

    const messagesQuery = useQuery(["messages", id], async () => {
        const response = await interactionsService.getAllForId(id ?? "");
        if (response) {
            return response.data;
        }
    });
    return (
        <div className="flow-root">
            <ul role="list" className="-mb-8">
                {messagesQuery.data
                    ?.filter(
                        (m) =>
                            (m.interactionFrom === id || m.interactionTo === id || m.interactionRegarding === id) &&
                            m.type === 1,
                    )
                    .map((activityItem, activityItemIdx) => (
                        <li key={activityItem.id}>
                            <div className="relative pb-8">
                                {activityItemIdx !== (messagesQuery.data ?? []).length - 1 ? (
                                    <span
                                        className="absolute top-7 left-7 -ml-px h-full w-0.5 bg-gray-200 dark:bg-gray-900"
                                        aria-hidden="true"
                                    />
                                ) : null}
                                <div className="relative flex items-start space-x-3">
                                    <>
                                        <div className="relative pl-2 pt-2 pr-2">
                                            {/* <img
                                            className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white"
                                            src={"https://picsum.photos/200"}
                                            alt=""
                                        /> */}
                                            <Avatar
                                                name={
                                                    usersQuery.data?.find(
                                                        (u) => u.id === (activityItem.interactionFrom ?? ""),
                                                    )?.name ?? ""
                                                }
                                                size="44"
                                                round={true}
                                                className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 dark:ring-gray-700 ring-slate-200"
                                            />

                                            {/* <span className="absolute -bottom-0.5 -right-1 rounded-tl bg-transparent px-0.5 py-px">
                                                <ChatBubbleLeftEllipsisIcon
                                                    className="h-5 w-5 text-gray-400"
                                                    aria-hidden="true"
                                                />
                                            </span> */}
                                        </div>
                                        <div className="min-w-0 flex-1">
                                            <div>
                                                <div className="text-sm">
                                                    <a
                                                        href={activityItem.interactionFrom}
                                                        className="font-medium text-gray-900 dark:text-gray-300"
                                                    >
                                                        {(usersQuery.data?.find(
                                                            (u) => u.id === (activityItem.interactionFrom ?? ""),
                                                        )?.name ?? "") +
                                                            " to " +
                                                            (usersQuery.data?.find(
                                                                (u) => u.id === (activityItem.interactionTo ?? ""),
                                                            )?.name ?? "")}

                                                        {/* {activityItem.interactionFrom} */}
                                                    </a>
                                                </div>
                                                <p className="mt-0.5 text-sm text-gray-500">
                                                    Wrote {timeAgo(activityItem.dateCreated).toString()}
                                                </p>
                                            </div>
                                            <div className="mt-2 text-sm text-gray-700">
                                                <p className="text-xs text-gray-400">
                                                    {new Date(activityItem.dateCreated).toLocaleString()}
                                                </p>
                                                <p>{activityItem.activity}</p>
                                            </div>
                                        </div>
                                    </>
                                </div>
                            </div>
                        </li>
                    ))}
            </ul>

            <NewMessage show={show} setShow={setShow} type={1} />
            <ShowMessage id={messageId} show={showMessage} setShow={setShowMessage} />
        </div>
    );
}
