import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import InputControlled from "../../../layout/input-controlled";
import ModalDialog from "../../../layout/modal-dialog";
import PrintPre from "../../../layout/print-pre";
import SelectNeoGen from "../../../layout/select-neogen";
import userService from "../../../services/user.service";
import { UTMLink } from "../../../typings/api/utm-links";
import { v4 as uuidv4 } from "uuid";
import { useRecoilValue } from "recoil";
import userAtom from "../../../atoms/userAtom";
import { ClearERCUser } from "../../../typings/api/clear-erc-user";
/*
{
  "id": "005b1aee-4a0f-47c6-b61a-ed226fc4af26",
  "lastUpdated": "2023-01-05T19:16:05.000Z",
  "lastEntityAdded": null,
  "code": "005b1aee-4a0f-47c6-b61a-ed226fc4af26",
  "source": null,
  "medium": null,
  "campaign": null,
  "term": null,
  "content": null,
  "notes": "",
  "owner": "7cc2a62d-d51c-4e2a-a8f3-fbcc1994535e"
}
*/

function compare(a: any, b: any) {
    if ((a.name ?? "").toLowerCase() < (b.name ?? "").toLowerCase()) {
        return -1;
    }
    if ((a.name ?? "").toLowerCase() > (b.name ?? "").toLowerCase()) {
        return 1;
    }
    return 0;
}

const defaultUtmLink: UTMLink = {
    lastUpdated: new Date(),
    lastEntityAdded: "",
    code: uuidv4(),
    source: "",
    medium: "",
    campaign: "",
    term: "",
    content: "",
    notes: "",
    owner: "",
    name: "",
};

export default function AddEditUtmLink({
    show,
    setShow,
    isEdit,
    utmLink,
    saveNew,
    saveEdit,
}: {
    show: boolean;
    setShow: (show: boolean) => void;
    isEdit: boolean;
    utmLink?: UTMLink;
    saveNew: (utmLink: UTMLink) => void;
    saveEdit: (utmLink: UTMLink) => void;
}) {
    const user = useRecoilValue<ClearERCUser>(userAtom);
    const [internalUtmLink, setInternalUtmLink] = useState<UTMLink>(defaultUtmLink);
    useEffect(() => {
        if (utmLink) {
            setInternalUtmLink(utmLink);
        } else {
            // alert(JSON.stringify(user));
            setInternalUtmLink((c) => {
                return { ...c, owner: user?.id ?? "" };
            });
        }
    }, [user, utmLink]);
    const usersQuery = useQuery(["users", "withNames"], async () => {
        const response = await userService.getAll();
        if (response) {
            return response.data.filter((u) => u.name !== "" && u.name !== null).sort(compare);
        }
        return [];
    });

    if (!show || usersQuery.isLoading) {
        return <></>;
    }

    return (
        <>
            <ModalDialog
                size="md"
                show={show}
                title={isEdit ? "Edit UTM Link" : "Add UTM Link"}
                close={() => setShow(false)}
                okText={isEdit ? "Save" : "Add"}
                okAction={() => {
                    if (isEdit) {
                        saveEdit(internalUtmLink);
                    } else {
                        saveNew(internalUtmLink);
                    }
                    setShow(false);
                }}
                cancelText="Cancel"
            >
                <div>
                    <p className="text-small mb-5 text-center font-semibold">
                        <span className="">UTM Code:</span> {internalUtmLink.code}
                    </p>
                </div>
                {/* <PrintPre>
                    {user?.id}
                    {usersQuery.data}
                </PrintPre> */}
                <SelectNeoGen
                    label="Owner"
                    className="mb-5"
                    value={internalUtmLink?.owner}
                    onChange={(e) => {
                        internalUtmLink.owner = e.toString();
                    }}
                    options={(usersQuery.data ?? []).sort(compare)}
                />
                <InputControlled
                    label="Name"
                    value={utmLink?.name ?? ""}
                    setValue={(e) => {
                        internalUtmLink.name = e;
                    }}
                />
                <InputControlled
                    label="Source"
                    value={utmLink?.source ?? ""}
                    setValue={(e) => {
                        internalUtmLink.source = e;
                    }}
                />
                <InputControlled
                    label="Medium"
                    value={utmLink?.medium ?? ""}
                    setValue={(e) => {
                        internalUtmLink.medium = e;
                    }}
                />
                <InputControlled
                    label="Campaign"
                    value={utmLink?.campaign ?? ""}
                    setValue={(e) => {
                        internalUtmLink.campaign = e;
                    }}
                />
                <InputControlled
                    label="Term"
                    value={utmLink?.term ?? ""}
                    setValue={(e) => {
                        internalUtmLink.term = e;
                    }}
                />
                <InputControlled
                    label="Content"
                    value={utmLink?.content ?? ""}
                    setValue={(e) => {
                        internalUtmLink.content = e;
                    }}
                />
                <InputControlled
                    label="Notes"
                    value={utmLink?.notes ?? ""}
                    setValue={(e) => {
                        internalUtmLink.notes = e;
                    }}
                />
            </ModalDialog>
        </>
    );
}
