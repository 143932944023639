import { useState } from "react";
import ModalDialog from "../../../../layout/modal-dialog";
import RadioButtonNeoGenControlled from "../../../../layout/radiobutton-neogen-controlled";
import Filter from "../../../../layout/filter";
import { useQuery } from "@tanstack/react-query";
import usersService from "../../../../services/users.service";
import { ClearERCUser } from "../../../../typings/api/clear-erc-user";
import pushRecordService from "../../../../services/push-record.service";

function compareUsers(a: ClearERCUser, b: ClearERCUser) {
    const aSort = a.name ?? a.email ?? "";
    const bSort = b.name ?? b.email ?? "";
    if (aSort.toLowerCase() < bSort.toLowerCase()) {
        return -1;
    }
    if (aSort.toLowerCase() > bSort.toLowerCase()) {
        return 1;
    }
    return 0;
}

export default function PushToRemote({
    close,
    show,
    type,
}: {
    close: () => void;
    show: boolean;
    type: "slack" | "monday";
}) {
    const [newUser, setNewUser] = useState(1);
    const [isNewUser, setIsNewUser] = useState(false);
    const [selectedUser, setSelectedUser] = useState("");
    const usersQuery = useQuery(["users", "sorted", compareUsers], async () => {
        const response = await usersService.getAll();
        if (response) {
            return response.data.sort(compareUsers);
        }
        return [];
    });

    if (!show) {
        return <></>;
    }

    return (
        <ModalDialog
            close={function (): void {
                close();
            }}
            show={show}
            title={"Push Customer To " + type}
            okAction={() => {
                if (type)
                    if (isNewUser) {
                        alert("Create new user");
                    } else {
                        // alert("Push existing user: " + selectedUser);
                        pushRecordService.pushRecordById(selectedUser, type);
                    }
            }}
        >
            <div className="pt-0">
                {/* <RadioButtonNeoGenControlled
                    onChange={function (idx: string | number, text?: string | undefined): void {
                        setIsNewUser(idx === 2);
                        setNewUser(Number(idx));
                    }}
                    value={newUser}
                    options={[
                        { id: 1, name: "Push existing user" },
                        { id: 2, name: "Create new user" },
                    ]}
                /> */}
                <div>
                    <div className="flex flex-col space-y-2 mt-5">
                        {!isNewUser && (
                            <Filter
                                hideReset={true}
                                label={"User to send"}
                                options={usersQuery.data?.map((u) => {
                                    return {
                                        id: u.id,
                                        name: (u.name ?? "") + " (Email: " + u.email + " Phone: " + u.phone + ")",
                                    };
                                })}
                                // value={interactionTo}
                                onChange={(value) => {
                                    // alert(value.toString());
                                    setSelectedUser(value.toString());
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </ModalDialog>
    );
}
